import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Link } from '~/shared/components';
import { breakpoints } from '~/theme';

export const StyledAsymmetricalWrapper = styled.div({});

export const StyledAsymmetricalColumn = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr',
    columnGap: 30,
    [breakpoints.md]: {
        gridTemplateColumns: '1fr 1fr',
    },
});

export const StyledAsymmetricalItem = styled.div<{ alignLeft: boolean }>(({ alignLeft }) => ({
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignLeft ? 'flex-start' : 'flex-end',
    justifyContent: 'center',
    [breakpoints.md]: {
        marginBottom: 0,
    },
}));

export const StyledInnerItem = styled.div<{ isWide: boolean }>(
    {
        width: '100%',
    },
    ifProp(
        'isWide',
        {},
        {
            [breakpoints.md]: {
                maxWidth: 450,
                width: 'calc((50vw - 25px) * 0.655)',
            },
        }
    )
);

export const StyledImage = styled.div<{ isWide: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        marginBottom: theme.spaces[5],
    }),
    ifProp(
        'isWide',
        {
            height: 0,
            paddingBottom: '60%',
            [breakpoints.md]: {
                maxHeight: 450,
                height: 'calc((50vw - 25px) * 0.641)',
            },
        },
        {
            height: 0,
            paddingBottom: '60%',
            [breakpoints.md]: {
                maxHeight: 650,
                height: 'calc((50vw - 25px) * 0.921)',
            },
        }
    )
);

export const StyledMetaInfo = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: theme.spaces[3],
}));

export const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'underline',
    },
}));
