import React, { memo, useMemo } from 'react';
import { Text, ImageLink } from '~/shared/components';
import {
    StyledAsymmetricalColumn,
    StyledAsymmetricalWrapper,
    StyledAsymmetricalItem,
    StyledImage,
    StyledInnerItem,
    StyledMetaInfo,
    StyledLink,
} from './styled';
import { generateUuid } from '~/shared/utils/jsx';
import {
    RelewiseResponsePageTags,
    RelevantContentItem,
    RelevantContentResponse,
} from '~/lib/data-contract';
import chunk from 'lodash/chunk';
import { theme } from '~/theme';
import { useComputed } from '~/shared/utils';
import { TagListLink } from '../TagListLink';
import NextLink from 'next/link';

interface CarouselProps {
    items: RelevantContentResponse[];
}

interface RenderItemMemoProps {
    item: RelevantContentItem;
    isEven: boolean;
    alignLeft: boolean;
}

const RenderItem = memo(({ item, isEven, alignLeft }: RenderItemMemoProps) => {
    return (
        <StyledAsymmetricalItem alignLeft={alignLeft}>
            <StyledInnerItem isWide={isEven}>
                <StyledImage isWide={isEven}>
                    {item.image?.src ? (
                        <ImageLink
                            withBorderRadius
                            linkProps={{
                                href: `${item.callToAction}`,
                                prefetch: false,
                            }}
                            imageProps={{
                                alt: '',
                                ...item.image,
                                height: item.image.height,
                                width: item.image.width,
                                objectFit: 'cover',
                                layout: 'fill',
                                draggable: false,
                            }}
                        />
                    ) : null}
                </StyledImage>
                <NextLink
                    href={item.callToAction as string}
                    passHref
                    prefetch={false}
                    legacyBehavior
                >
                    <StyledLink>
                        <Text variant="display6" style={{ marginBottom: theme.spaces[1] }}>
                            {item.headline}
                        </Text>
                    </StyledLink>
                </NextLink>
                <StyledMetaInfo>
                    {item.tags &&
                        (JSON.parse(item.tags) as RelewiseResponsePageTags[])
                            .map((item) => item.tagsList)
                            .map(
                                (tags) =>
                                    tags &&
                                    tags.map((tag, index) => (
                                        <TagListLink key={index}>{tag}</TagListLink>
                                    )),
                            )}
                </StyledMetaInfo>
            </StyledInnerItem>
        </StyledAsymmetricalItem>
    );
});

export const AsymmetricalGrid = ({ items }: CarouselProps) => {
    const relevantItems = useMemo(() => {
        if (!items) return [];

        const itemsList: RelevantContentItem[] = [];

        items.forEach((group) => {
            itemsList.push(...group.relevantContent);
        });

        return itemsList;
    }, [items]);

    const itemsChunk = useMemo(() => {
        return chunk(relevantItems, 2);
    }, [relevantItems]);

    const baseKey = useComputed(() => {
        return generateUuid();
    }, []);
    return (
        <StyledAsymmetricalWrapper>
            {itemsChunk.map((chunk, outerIndex) => (
                <StyledAsymmetricalColumn key={`${baseKey}_${outerIndex}`}>
                    {chunk.map((chunk, pageIndex) => (
                        <RenderItem
                            key={`${baseKey}_${outerIndex}_${pageIndex}`}
                            item={chunk}
                            isEven={outerIndex % 2 === pageIndex}
                            alignLeft={outerIndex % 2 === 1}
                        />
                    ))}
                </StyledAsymmetricalColumn>
            ))}
        </StyledAsymmetricalWrapper>
    );
};
