import React, { useMemo, useRef, useState } from 'react';
import { CallToAction, Text, Image, ImageLink, MaxWidth, Gutter } from '~/shared/components';
import {
    StyledButtonWrapper,
    StyledCarouselButtonContainer,
    StyledCarouselWrapper,
    StyledImage,
    StyledListItem,
    StyledMetaInfo,
    StyledRelativeContainer,
} from './styled';
import {
    ImageMedia,
    RelewiseResponsePageTags,
    RelevantContentItem,
    RelevantContentResponse,
} from '~/lib/data-contract';
import { useTranslation } from '~/shared/utils/translation';
import { TagListLink } from '../TagListLink';
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore, { SwiperOptions } from 'swiper';
import { CarouselButton } from '~/shared/components/Carousel/components/CarouselButton/CarouselButton';
import { useSwiperHelper } from '~/shared/hooks/useSwiperHelper/useSwiperHelper';
import fallbackImage from '$assets/fallback-product.svg';
interface CarouselProps {
    items: RelevantContentResponse[];
}

export const Carousel = ({ items }: CarouselProps) => {
    const moduleWidthRef = useRef<HTMLDivElement>(null);
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore | undefined>();
    const { hasNext, hasPrev, slideNext, slidePrev } = useSwiperHelper(swiperInstance);
    const { translate } = useTranslation();

    const relevantItems = useMemo(() => {
        if (!items) return [];

        const itemsList: RelevantContentItem[] = [];

        items.forEach((group) => {
            itemsList.push(...group.relevantContent);
        });

        return itemsList;
    }, [items]);

    const getImage = (image?: ImageMedia) => {
        if (image?.src) {
            return (
                <Image
                    {...image}
                    height={image.height}
                    width={image.width}
                    objectFit="cover"
                    layout="fill"
                    draggable={false}
                />
            );
        }
        return <Image src={fallbackImage} objectFit="cover" layout="fill" draggable={false} />;
    };

    const articles = useMemo(() => {
        return relevantItems.map((item, index) => {
            return (
                <StyledListItem key={index}>
                    {item.callToAction && item.image && (
                        <StyledImage>
                            <ImageLink
                                withBorderRadius
                                draggable={false}
                                linkProps={{
                                    href: item.callToAction,
                                    passHref: true,
                                    prefetch: false,
                                }}
                                imageProps={{
                                    alt: '',
                                    ...item.image,
                                    height: item.image.height,
                                    width: item.image.width,
                                    objectFit: 'cover',
                                    layout: 'fill',
                                    draggable: false,
                                }}
                            />
                        </StyledImage>
                    )}

                    {!item.callToAction && item.image && (
                        <StyledImage>{getImage(item.image)}</StyledImage>
                    )}

                    <Text variant="display4">{item.headline}</Text>
                    <StyledMetaInfo>
                        {item.tags &&
                            (JSON.parse(item.tags) as RelewiseResponsePageTags[])
                                .map((item) => item.tagsList)
                                .map(
                                    (tags) =>
                                        tags &&
                                        tags.map((tag, index) => (
                                            <TagListLink key={index}>{tag}</TagListLink>
                                        )),
                                )}
                    </StyledMetaInfo>
                    {item.callToAction && (
                        <StyledButtonWrapper>
                            <CallToAction
                                callToAction={{
                                    url: item.callToAction,
                                    title: translate('Kompan.RelevantContent.ButtonLabelSeeMore'),
                                    text: translate('Kompan.RelevantContent.ButtonLabelSeeMore'),
                                    variation: 'Plain',
                                    iconName: 'arrow right',
                                    iconAlignment: 'start',
                                }}
                            />
                        </StyledButtonWrapper>
                    )}
                </StyledListItem>
            );
        });
    }, [relevantItems]);
    return (
        <StyledCarouselWrapper ref={moduleWidthRef}>
            <MaxWidth>
                <Gutter>
                    <StyledRelativeContainer>
                        {hasPrev && (
                            <StyledCarouselButtonContainer alignment="left">
                                <CarouselButton direction="left" onClick={slidePrev} />
                            </StyledCarouselButtonContainer>
                        )}
                        {hasNext && (
                            <StyledCarouselButtonContainer alignment="right">
                                <CarouselButton direction="right" onClick={slideNext} />
                            </StyledCarouselButtonContainer>
                        )}
                        <Swiper
                            role="list"
                            {...swiperConfig}
                            onSwiper={(instance) => {
                                setSwiperInstance(instance);
                            }}
                        >
                            {articles.map((item, index) => (
                                <SwiperSlide key={index}>{item}</SwiperSlide>
                            ))}
                        </Swiper>
                    </StyledRelativeContainer>
                </Gutter>
            </MaxWidth>
        </StyledCarouselWrapper>
    );
};

const swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    slidesPerGroupAuto: true,
    autoplay: false,
    speed: 500,
    spaceBetween: 30,
    mousewheel: {
        forceToAxis: true, // Enables horizontal scroll, but prevents carousel from hijacking regular vertical scroll
    },
    a11y: {
        enabled: false, // Fixes jumps when clicking slide outside natural viewport
    },
};
