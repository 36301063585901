import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

const imageHeight = {
    sm: 270,
    lg: 380,
};

export const StyledRelativeContainer = styled.div({
    position: 'relative',
});

export const StyledCarouselButtonContainer = styled.div<{ alignment: 'left' | 'right' }>(
    ({ theme }) => ({
        position: 'absolute',
        opacity: 0,
        borderRadius: 20,
        transform: 'translateY(-50%)',
        zIndex: 1000,
        transition: `${theme.animations.timingShort} ${theme.animations.easeOutCubic}`,
        transitionProperty: 'opacity',
        top: imageHeight.sm / 2,
        [breakpoints.sm]: {
            top: imageHeight.lg / 2,
        },
        [breakpoints.xs]: {
            opacity: 1,
        },
    }),
    ifProp(
        { alignment: 'left' },
        {
            left: 10,
            [breakpoints.lg]: {
                left: 40,
            },
        },
        {
            right: 10,
            [breakpoints.lg]: {
                right: 40,
            },
        }
    )
);

export const StyledCarouselWrapper = styled.div({
    position: 'relative',
    overflow: 'hidden',
    [`&:hover ${StyledCarouselButtonContainer}`]: {
        opacity: 1,
    },
    '.swiper-slide': {
        width: 'auto',
    },
    '.swiper': {
        overflow: 'visible',
    },
    '.no-js &': {
        '.swiper-wrapper': {
            display: 'flex',
            flexWrap: 'wrap',
        },
        '.swiper-slide': {
            padding: '15px',
        },
    },
});

export const StyledListItem = styled.div({
    display: 'flex',
    gap: 30,
    flexDirection: 'column',
    width: 320,
    [breakpoints.sm]: {
        width: 459,
    },
});

export const StyledImage = styled.div(() => ({
    position: 'relative',
    width: '100%',
    height: imageHeight.sm,
    maxWidth: '100%',
    [breakpoints.sm]: {
        height: imageHeight.lg,
    },
    img: {
        borderRadius: '45px 15px',
    },
}));

export const StyledMetaInfo = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
}));

export const StyledButtonWrapper = styled.div({
    paddingLeft: 5,
});
