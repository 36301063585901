import { ifProp, switchProp } from 'styled-tools';
import styled from '@emotion/styled';
import { OptionProps } from './components/Option';
import { StyledInputField } from '../InputField';
import { motion } from 'framer-motion';

type StyledOptionProps = Pick<OptionProps, 'selected' | 'disabled'>;

export const StyledOption = styled.li<StyledOptionProps>(
    ({ theme }) => ({
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        listStyle: 'none',
        padding: `1.8rem ${theme.spaces[4]}`,
        cursor: 'pointer',
    }),
    ifProp('disabled', {
        opacity: 0.5,
        cursor: 'not-allowed',
    }),
);

export const StyledOptionsWrapper = styled.div(({ theme }) => ({
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: theme.zIndexes[1],
    width: '100%',
    margin: `${theme.spaces[2]} 0 0`,
    backgroundColor: theme.colors.white,
    borderTop: 'none',
    boxSizing: 'border-box',
    outline: 'none',
    boxShadow: theme.elevations.dropdown,
    borderRadius: theme.borderRadius.dropdown,
}));

export const StyledOptions = styled(motion.ul)(({ theme }) => ({
    padding: theme.spaces[4],
}));

export const StyledIcon = styled.span<{ isOpen: boolean }>(({ theme, isOpen }) => ({
    transition: `${theme.animations.timingMedium} ${theme.animations.easeSmooth}`,
    transform: isOpen ? 'translate3d(0, 0.6rem, 0)' : 'translate3d(0, 0rem, 0)',
    lineHeight: 0,
    color: theme.colors.red,
}));

export const StyledSelector = styled.div<{ disabled: boolean }>(
    switchProp('disabled', {
        true: {
            [`${StyledInputField}, input`]: {
                cursor: 'default',
            },
            [`${StyledIcon}`]: {
                opacity: 0.5,
            },
        },
        false: {
            [`${StyledInputField}, input`]: {
                cursor: 'pointer',
            },
        },
    }),
);

export const StyledSelectedOptions = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.grey05,
    borderRadius: theme.borderRadius.input,
}));
