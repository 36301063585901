import { RefObject, useEffect, useMemo } from 'react';
import { useMotionValue } from 'framer-motion';
import { isBrowser } from '~/shared/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const useTrackHeight = (ref: RefObject<HTMLElement>) => {
    const measuredHeight = useMotionValue(0);
    const supportsResizeObserver = isBrowser && typeof window.ResizeObserver !== 'undefined';

    const observer = useMemo(() => {
        if (supportsResizeObserver) {
            return new ResizeObserver((entries) => {
                if (entries[0]?.borderBoxSize?.[0]?.blockSize !== undefined) {
                    const { blockSize: height } = entries[0].borderBoxSize[0];
                    measuredHeight.set(height);
                } else {
                    const { height } = entries[0].target.getBoundingClientRect();
                    measuredHeight.set(height);
                }
            });
        }
        return {
            observe: noop,
            unobserve: noop,
            disconnect: noop,
        } as ResizeObserver;
    }, []);

    useEffect(() => {
        if (!supportsResizeObserver || !ref?.current) return;
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return measuredHeight;
};
