import styled from '@emotion/styled';
import { Text } from '$shared/components';
import { StyledInputField } from '~/shared/components/FormElements/components/InputField';
import { breakpoints } from '~/theme';

export const StyledFooterWrapper = styled.div<{ withPadding?: boolean }>(
    ({ theme, withPadding = true }) => ({
        display: 'flex',
        justifyContent: 'center',
        paddingTop: withPadding ? theme.spaces[7] : 0,
    })
);

export const StyledHeader = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spaces[5],
    alignItems: 'end',
    width: '100%',
    margin: '0 auto',
    paddingBottom: theme.spaces[7],
    [breakpoints.sm]: {
        gridTemplateColumns: '2fr 1fr',
    },
}));

export const StyledSubheadline = styled(Text)(({ theme }) => ({
    marginBottom: theme.spaces[4],
    color: theme.colors.grey60,
}));

export const StyledHeaderButtonColumn = styled.div(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    [breakpoints.sm]: {
        justifyContent: 'flex-end',
    },
}));

export const StyledHeaderTextColumn = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column-reverse',
}));

export const StyledFilterButtons = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    flexShrink: 0,
    flexWrap: 'wrap',
    gap: theme.spaces[3],
    marginBottom: theme.spaces[4],
}));

export const StyledUserFiltersTopRow = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: `0 0 ${theme.spaces[2]} 0`,
}));

export const StyledUserFilters = styled.div(({ theme }) => ({
    marginBottom: theme.spaces[7],
    [`${StyledInputField} input`]: {
        fontSize: '1em',
        height: '1em',
        boxSizing: 'content-box',
        padding: `calc(${theme.spaces[4]} + ${0.2}rem) ${theme.spaces[6]} ${theme.spaces[4]}`,
    },
}));

export const StyledSortOrderSelector = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [breakpoints.sm]: {
        marginLeft: 'auto',
        paddingLeft: theme.spaces[6],
        width: 'auto',
    },
}));

export const StyledSortOrderLabel = styled(Text)(({ theme }) => ({
    marginRight: theme.spaces[3],
    flexShrink: 0,
}));

export const StyledContentCategories = styled.div(({ theme }) => ({
    marginBottom: theme.spaces[7],
}));
