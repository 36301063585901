import {
    ContentAttributeSorting,
    ContentDataSorting,
    ContentPopularitySorting,
    ContentRelevanceSorting,
} from '@relewise/client';
import { ContentSortingEnum } from '~/lib/data-contract';
import {
    RelewiseContentDataTagsTypes,
    RelewiseProductSearchFacetTypes,
} from '../../M140ProductsList';

export const categoryFilter = {
    $type: RelewiseProductSearchFacetTypes.CONTENT_DATA_STRING,
    field: 'Data',
    key: RelewiseContentDataTagsTypes.L2Category,
    settings: {
        alwaysIncludeSelectedInAvailable: true,
    },
    collectionFilterType: 'Or',
};

export const userFilters = [
    {
        $type: RelewiseProductSearchFacetTypes.CONTENT_DATA_STRING,
        field: 'Data',
        key: RelewiseContentDataTagsTypes.Sector,
        settings: {
            alwaysIncludeSelectedInAvailable: true,
        },
        collectionFilterType: 'Or',
    },
    {
        $type: RelewiseProductSearchFacetTypes.CONTENT_DATA_STRING,
        field: 'Data',
        key: RelewiseContentDataTagsTypes.Theme,
        settings: {
            alwaysIncludeSelectedInAvailable: true,
        },
        collectionFilterType: 'Or',
    },
];

type SortOptionType = {
    key: ContentSortingEnum;
    label?: string;
    relewiseData:
        | ContentAttributeSorting
        | ContentDataSorting
        | ContentPopularitySorting
        | ContentRelevanceSorting;
};

export const sortOptions: SortOptionType[] = [
    {
        key: ContentSortingEnum.RELEVANCE,
        label: 'Kompan.RelevantContent.SortOptionRelevance',
        relewiseData: {
            $type: `Relewise.Client.DataTypes.Search.Sorting.Content.ContentRelevanceSorting, Relewise.Client`,
            order: 'Descending',
        },
    },
    /*
    Popularity is disabled until we have tracking data for relewise
    {
        key: ContentSortingEnum.POPULARITY,
        label: 'Kompan.RelevantContent.SortOptionPopularity',
        relewiseData: {
            order: 'Descending',
            $type: `Relewise.Client.DataTypes.Search.Sorting.Content.ContentPopularitySorting, Relewise.Client`,
        }
    },
    */
    {
        key: ContentSortingEnum.ALPHABETICAL_ASC,
        label: 'Kompan.RelevantContent.SortOptionAlphabeticalAsc',
        relewiseData: {
            $type: `Relewise.Client.DataTypes.Search.Sorting.Content.ContentAttributeSorting, Relewise.Client`,
            order: 'Ascending',
            attribute: 'DisplayName',
            mode: 'Alphabetical',
        },
    },
    {
        key: ContentSortingEnum.ALPHABETICAL_DESC,
        label: 'Kompan.RelevantContent.SortOptionAlphabeticalDesc',
        relewiseData: {
            $type: `Relewise.Client.DataTypes.Search.Sorting.Content.ContentAttributeSorting, Relewise.Client`,
            order: 'Descending',
            attribute: 'DisplayName',
            mode: 'Alphabetical',
        },
    },
    /*
    Sorting currently uses LastPublishDate instead of CreatedDate to allow editors to republish to boost a page location.
    {
        key: ContentSortingEnum.CREATED_DATE_ASC,
        label: 'Kompan.RelevantContent.SortOptionCreatedDateAsc',
        relewiseData: {
            $type: `Relewise.Client.DataTypes.Search.Sorting.Content.ContentDataSorting, Relewise.Client`,
            order: 'Ascending',
            key: 'CreatedDate',
            mode: 'Numerical',
        }
    },
    {
        key: ContentSortingEnum.CREATED_DATE_DESC,
        label: 'Kompan.RelevantContent.SortOptionCreatedDateDesc',
        relewiseData: {
            $type: `Relewise.Client.DataTypes.Search.Sorting.Content.ContentDataSorting, Relewise.Client`,
            order: 'Descending',
            key: 'CreatedDate',
            mode: 'Numerical',
        }
    },
    */
    {
        key: ContentSortingEnum.PUBLISH_DATE_ASC,
        label: 'Kompan.RelevantContent.SortOptionCreatedDateAsc',
        relewiseData: {
            $type: `Relewise.Client.DataTypes.Search.Sorting.Content.ContentDataSorting, Relewise.Client`,
            order: 'Ascending',
            key: 'LastPublishedDate',
            mode: 'Numerical',
        },
    },
    {
        key: ContentSortingEnum.PUBLISH_DATE_DESC,
        label: 'Kompan.RelevantContent.SortOptionCreatedDateDesc',
        relewiseData: {
            $type: `Relewise.Client.DataTypes.Search.Sorting.Content.ContentDataSorting, Relewise.Client`,
            order: 'Descending',
            key: 'LastPublishedDate',
            mode: 'Numerical',
        },
    },
];

export const sortByPrioritized: ContentDataSorting = {
    $type: `Relewise.Client.DataTypes.Search.Sorting.Content.ContentDataSorting, Relewise.Client`,
    mode: 'Alphabetical',
    key: 'Prioritized',
    order: 'Descending',
};
