import { useMemo } from 'react';
import { DropdownItem } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';
import {
    Facet,
    ProductCategoryListButton,
    RelewiseContentDataTagsTypes,
    SelectedFacet,
} from '$templates/blocks/components/M140ProductsList';
import {
    StyledProductCategoryList,
    StyledProductCategoryListContainer,
} from '$templates/blocks/components/M140ProductsList/components/filters/ProductCategoryList/styled';

type ContentCategoryListProps = {
    untouchedFacet?: Facet;
    processedFacet?: Facet;
    selectedFacets: SelectedFacet[];
    onCategoryClick: (item: DropdownItem) => void;
    onAllClick: () => void;
};
export const ContentCategoryList = ({
    untouchedFacet,
    processedFacet,
    selectedFacets,
    onCategoryClick,
    onAllClick,
}: ContentCategoryListProps) => {
    const { translate } = useTranslation();

    const selectedCategories = useMemo(() => {
        const l2Selected = selectedFacets.find(
            (facet) => facet.attribute == RelewiseContentDataTagsTypes.L2Category
        );
        return l2Selected?.items?.map((item) => item.value as string) || [];
    }, [selectedFacets]);

    const isCategoryOptionAvailable = (category: DropdownItem) => {
        if (!processedFacet) return true;

        const targetItem = processedFacet.items?.find((item) => item.value == category.value);
        return targetItem?.count && targetItem.count > 0;
    };

    return (
        <StyledProductCategoryListContainer>
            {untouchedFacet?.items && !!untouchedFacet.items.length && (
                <StyledProductCategoryList>
                    <li key={`productCategoryList_all`}>
                        <ProductCategoryListButton
                            isActive={selectedCategories.length == 0}
                            isDisabled={false}
                            value={'all'}
                            onClick={() => onAllClick()}
                        >
                            {translate('Kompan.Commerce.AllCategories')}
                        </ProductCategoryListButton>
                    </li>

                    {untouchedFacet.items.map((category) => {
                        return (
                            category.label && (
                                <li key={`productCategoryList_${category.value}`}>
                                    <ProductCategoryListButton
                                        isActive={
                                            !!selectedCategories.includes(category.value as string)
                                        }
                                        isDisabled={!isCategoryOptionAvailable(category)}
                                        value={category.value as string}
                                        onClick={() => onCategoryClick(category)}
                                    >
                                        {category.label}
                                    </ProductCategoryListButton>
                                </li>
                            )
                        );
                    })}
                </StyledProductCategoryList>
            )}
        </StyledProductCategoryListContainer>
    );
};
