import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { motion, useSpring } from 'framer-motion';
import { forwardRef, useEffect, useRef } from 'react';
import { useTrackHeight } from './hooks/useTrackHeight';

const StyledAnimatedAutoHeightContainer = styled(motion.div)({
    overflow: 'hidden',
    willChange: 'height',
});

export type AnimatedAutoHeightProps = {
    children?: React.ReactNode | React.ReactNode[];
    smallOverflow?: boolean; // If there's a component with negative margin or special box-sizing that needs to overflow a few px, flag this as true
    maxHeight?: number;
};

/**
 * Use to fluidly animate between different values of height 'auto'.
 *
 * Uses ResizeObserver to detect any changes in child content size and smoothly animates height to match
 */
export const AnimatedAutoHeight = forwardRef<HTMLDivElement, AnimatedAutoHeightProps>(
    ({ children, smallOverflow, maxHeight }, ref) => {
        const isFirstRender = useRef(true);
        const contentRef = useRef<HTMLDivElement | null>(null);
        const measuredHeight = useTrackHeight(contentRef);
        const theme = useTheme();
        const height = useSpring(measuredHeight, theme.animations.springDefault);

        useEffect(() => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
            }
        }, []);

        return (
            <StyledAnimatedAutoHeightContainer
                ref={ref}
                style={{
                    height: isFirstRender.current || maxHeight ? 'auto' : height,
                    margin: smallOverflow ? '-5px' : undefined,
                    maxHeight: maxHeight ? maxHeight : 'none',
                    overflow: maxHeight ? 'auto' : 'unset',
                }}
            >
                <div style={{ padding: smallOverflow ? '5px' : undefined }} ref={contentRef}>
                    {children}
                </div>
            </StyledAnimatedAutoHeightContainer>
        );
    }
);
