import React, { Fragment, memo, ReactNode, useMemo } from 'react';
import {
    ContentSortingEnum,
    M89RelevantContentLayoutEnum,
    M89RelevantContentModule,
    PageSizeTypeEnum,
    TranslationKey,
} from '~/lib/data-contract';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { ModuleContainer } from '$templates/blocks/components/ModuleContainer';
import { OneColumn } from './components/OneColumn';
import { ThreeColumn } from './components/ThreeColumn';
import { Carousel } from './components/Carousel';
import { AsymmetricalGrid } from './components/AsymmetricalGrid';
import {
    StyledContentCategories,
    StyledFilterButtons,
    StyledFooterWrapper,
    StyledHeader,
    StyledHeaderButtonColumn,
    StyledHeaderTextColumn,
    StyledSortOrderLabel,
    StyledSortOrderSelector,
    StyledSubheadline,
    StyledUserFilters,
    StyledUserFiltersTopRow,
} from './styled';
import {
    Button,
    Selector,
    DropdownItem,
    Gutter,
    Icon,
    MaxWidth,
    Text,
    CallToAction,
} from '~/shared/components';
import ArrowDown from '$icons/arrow-down.svg';
import { useTranslation } from '~/shared/utils/translation';
import { useRelewiseRelevantContent } from './hooks/useRelewiseRelevantContent';
import { mapRelewiseContentToRelevantContent } from './utils/mapRelewiseContentToRelevantContent';
import { FiltersDrawer } from './components/FiltersDrawer/FiltersDrawer';
import {
    Facet,
    hiddenFacets,
    RelewiseContentDataTagsTypes,
    SelectedFacetDropdown,
    SelectedFilters,
} from '../M140ProductsList';
import { ContentCategoryList } from './components/ContentCategoryList';
import { sortOptions } from './lib/relewiseFilters';
import { SelectorOption } from '~/shared/components/FormElements/components/Selector';
import { getPageSizeNumber } from './utils/getPageSizeNumber';
import { EventBus } from '~/shared/utils/event-bus/event-bus';
import { Headline } from '$templates/blocks/components/Headline';

export type M89RelevantContentProps = M89RelevantContentModule;

export const M89RelevantContent = ({
    headline,
    subHeadline,
    callToAction,
    contentLayout = M89RelevantContentLayoutEnum.THREE_COLUMN,
    pageSize = PageSizeTypeEnum.SNIPPET,
    relevantTags,
    loadmore,
    pageElementIndex,
    showUserFilters = false,
    showCategoryFilters = false,
    showSorting = false,
    defaultSort = ContentSortingEnum.PUBLISH_DATE_DESC,
    ...rest
}: M89RelevantContentProps) => {
    const pageSizeNumber = useMemo(() => {
        const size = getPageSizeNumber(pageSize, contentLayout);
        return size;
    }, [pageSize, contentLayout]);

    const tagsAsJson = useMemo(() => {
        return JSON.parse(relevantTags);
    }, [relevantTags]);
    const {
        data,
        hasNextPage,
        fetchNextPage,
        facetButtons,
        facets,
        isFetchingNextPage,
        selectedFacets,
        toggleFacetOption,
        showFiltersDrawer,
        setShowFiltersDrawer,
        toggleBooleanFacetOption,
        clearAllFacets,
        setFacetSelectedValues,
        clearSelectedFacetByKey,
        totalHits,
        isNoResults,
        l2Categories,
        selectedSort,
        setSelectedSort,
    } = useRelewiseRelevantContent({
        tags: tagsAsJson,
        pageSize: pageSizeNumber,
        defaultSorting: defaultSort,
    });
    const { translate } = useTranslation();

    const content = useMemo(() => {
        const mappedData = (data?.pages || []).map((page) => {
            return {
                pageSize: pageSizeNumber ?? 0,
                totalNumberOfPages: 0,
                currentIndex: 0,
                relevantContent: mapRelewiseContentToRelevantContent(page),
            };
        });
        switch (contentLayout) {
            case M89RelevantContentLayoutEnum.THREE_COLUMN:
                return <ThreeColumn items={mappedData} />;
            case M89RelevantContentLayoutEnum.ONE_COLUMN:
                return <OneColumn items={mappedData} />;
            case M89RelevantContentLayoutEnum.CAROUSEL:
                return <Carousel items={mappedData} />;
            case M89RelevantContentLayoutEnum.ASYMMETRICAL_GIRD:
                return <AsymmetricalGrid items={mappedData} />;
            default:
                return <h1>Not implementet</h1>;
        }
    }, [data, contentLayout, data?.pages, data?.pages?.length]);

    const selectedFacetButtons = useMemo(() => {
        if (selectedFacets.length == 0) return [];

        return selectedFacets
            .filter((facet) => {
                return facetButtons.includes(facet.attribute as RelewiseContentDataTagsTypes);
            })
            .map((facet) => {
                return facet.items.map((item) => {
                    return {
                        label: item.label,
                        value: item.value,
                        attribute: facet.attribute,
                    };
                });
            })
            .reduce((flatten, arr) => [...flatten, ...arr], []);
    }, [selectedFacets]);

    const onSelectedFacetClick = (item: SelectedFacetDropdown) => {
        const { attribute, ...dropdownItem } = item;
        toggleFacetOption(attribute, dropdownItem);
    };

    const getSelectedFilterCount = (facet: Facet) => {
        const selected = [...selectedFacets]?.find(
            (selectedFacet) => selectedFacet.attribute === facet.attribute,
        );
        return selected?.items.length ? selected.items.length : 0;
    };

    const onFacetChange = (
        event: React.ChangeEvent<HTMLInputElement> | undefined,
        facet: Facet,
        item?: DropdownItem,
    ) => {
        const isChecked = event?.target?.checked ?? false;
        if (facet.displayType == 'BOOLEAN') {
            toggleBooleanFacetOption(facet.attribute, isChecked);
        } else if (facet.displayType == 'MULTI_CHECKBOX') {
            if (!item) return;
            toggleFacetOption(facet.attribute, item);
        } else if (facet.displayType == 'RANGE') {
            console.log('range facet not yet implemented');
        }
    };

    const visibleFacets = useMemo(() => {
        return [...facets].filter((facet) =>
            facetButtons.includes(facet.attribute as RelewiseContentDataTagsTypes),
        );
    }, [facets, hiddenFacets]);

    const processedL2Categories = useMemo(() => {
        return facets.find((facet) => facet.attribute == RelewiseContentDataTagsTypes.L2Category);
    }, [facets]);

    const onCategoryItemClick = (item: DropdownItem) => {
        setFacetSelectedValues(RelewiseContentDataTagsTypes.L2Category, [item]);
    };

    const onAllCategoriesClick = () => {
        clearSelectedFacetByKey(RelewiseContentDataTagsTypes.L2Category);
    };

    const sortDropdownOptions = sortOptions.map((option) => {
        return {
            title: translate(option.label as TranslationKey, option.label),
            value: `${option.key}`,
        };
    });

    const onSortChange = (item: SelectorOption | undefined | null) => {
        if (!item) return;
        setSelectedSort(item.value as ContentSortingEnum);
    };

    const selectedSortOption = useMemo(() => {
        return sortOptions.find((option) => option.key == selectedSort);
    }, [selectedSort, sortOptions]);

    const onFilterButtonClick = (facet: Facet) => {
        setShowFiltersDrawer(true);
        setTimeout(() => {
            EventBus.emit(`AccordionOpen`, `FacetAccordion_${facet.attribute}`);
            EventBus.emit(`AccordionScrollTo`, `FacetAccordion_${facet.attribute}`);
        });
    };

    return (
        <ModuleContainer
            hasGutter={false}
            fullWidth={contentLayout === 'Carousel'}
            pageElementIndex={pageElementIndex}
            {...rest}
        >
            {headline && (
                <MaxWidth>
                    <Gutter>
                        <StyledHeader>
                            <StyledHeaderTextColumn>
                                {headline && <Headline variant="display3">{headline}</Headline>}
                                {subHeadline && (
                                    <StyledSubheadline variant="caption" as="p">
                                        {subHeadline}
                                    </StyledSubheadline>
                                )}
                            </StyledHeaderTextColumn>
                            <StyledHeaderButtonColumn>
                                {callToAction && <CallToAction callToAction={callToAction} />}
                            </StyledHeaderButtonColumn>
                        </StyledHeader>
                    </Gutter>
                </MaxWidth>
            )}

            {showCategoryFilters && l2Categories?.items && l2Categories.items.length > 0 && (
                <MaxWidth>
                    <Gutter>
                        <StyledContentCategories>
                            <ContentCategoryList
                                untouchedFacet={l2Categories}
                                processedFacet={processedL2Categories}
                                selectedFacets={selectedFacets}
                                onCategoryClick={onCategoryItemClick}
                                onAllClick={onAllCategoriesClick}
                            />
                        </StyledContentCategories>
                    </Gutter>
                </MaxWidth>
            )}

            {(showUserFilters || showSorting) && (
                <MaxWidth>
                    <Gutter>
                        <StyledUserFilters>
                            <StyledUserFiltersTopRow>
                                {showUserFilters && (
                                    <StyledFilterButtons>
                                        {visibleFacets.map((facet) => {
                                            const selectCount = getSelectedFilterCount(facet);
                                            return (
                                                <Button
                                                    key={facet.label}
                                                    variant={selectCount > 0 ? 'Red' : 'Ghost'}
                                                    disableHoverAnimation={true}
                                                    onClick={() => onFilterButtonClick(facet)}
                                                    icon={
                                                        <Icon>
                                                            <ArrowDown />
                                                        </Icon>
                                                    }
                                                >
                                                    {translate(
                                                        `Kompan.RelevantContent.Filters${facet.label}` as TranslationKey,
                                                        facet.label,
                                                    )}
                                                    {selectCount > 0 && ` (${selectCount})`}
                                                </Button>
                                            );
                                        })}
                                        <FiltersDrawer
                                            isOpen={showFiltersDrawer}
                                            onClose={() => setShowFiltersDrawer(false)}
                                            onClearAll={() => clearAllFacets()}
                                            facets={visibleFacets}
                                            selectedFacets={selectedFacets}
                                            onFacetChange={onFacetChange}
                                            submitTitle={translate(
                                                'Kompan.RelevantContent.ShowXResults',
                                            ).replace('[x]', `${totalHits}`)}
                                        />
                                    </StyledFilterButtons>
                                )}

                                {showSorting && (
                                    <StyledSortOrderSelector>
                                        <StyledSortOrderLabel as="label" variant={'body'}>
                                            {translate('Kompan.RelevantContent.SortByLabel')}
                                        </StyledSortOrderLabel>
                                        <Selector
                                            onChangeHandler={onSortChange}
                                            value={translate(
                                                selectedSortOption?.label as TranslationKey,
                                            )}
                                            name={'sortorder'}
                                            options={sortDropdownOptions}
                                        />
                                    </StyledSortOrderSelector>
                                )}
                            </StyledUserFiltersTopRow>

                            <SelectedFilters
                                filters={selectedFacetButtons}
                                onClick={onSelectedFacetClick}
                                onClearAll={clearAllFacets}
                            />
                        </StyledUserFilters>
                    </Gutter>
                </MaxWidth>
            )}

            <ConditionalContained isFullWidth={contentLayout == 'Carousel'}>
                {content}
            </ConditionalContained>

            {isNoResults && (showUserFilters || showCategoryFilters) && (
                <MaxWidth>
                    <Gutter>
                        <Text as="h2" variant="display4">
                            {translate('Kompan.RelevantContent.NoContentResults')}
                        </Text>
                    </Gutter>
                </MaxWidth>
            )}

            {loadmore && hasNextPage && contentLayout !== 'Carousel' && (
                <StyledFooterWrapper>
                    <Button
                        variant="Transparent"
                        description="Load more"
                        icon={
                            <Icon size="sm">
                                <ArrowDown />
                            </Icon>
                        }
                        onClick={fetchNextPage as unknown as (e?: unknown) => void | Promise<void>}
                    >
                        {isFetchingNextPage
                            ? translate('Kompan.RelevantContent.ButtonLabelLoading')
                            : translate('Kompan.RelevantContent.ButtonLabelLoadMore')}
                    </Button>
                </StyledFooterWrapper>
            )}
        </ModuleContainer>
    );
};

const ConditionalContained = memo(
    ({ isFullWidth, children }: { isFullWidth: boolean; children: ReactNode | ReactNode[] }) => {
        if (!isFullWidth) {
            return (
                <MaxWidth>
                    <Gutter>{children}</Gutter>
                </MaxWidth>
            );
        }
        return <Fragment>{children}</Fragment>;
    },
);

export default withErrorBoundary(M89RelevantContent);
