import { M89RelevantContentLayoutEnum, PageSizeTypeEnum } from '~/lib/data-contract';

export const getPageSizeNumber = (
    pageSize: PageSizeTypeEnum,
    contentLayout: M89RelevantContentLayoutEnum
) => {
    let size = 6;
    const isLong = pageSize === PageSizeTypeEnum.LONG_LIST;
    switch (contentLayout) {
        case M89RelevantContentLayoutEnum.THREE_COLUMN:
            size = isLong ? 15 : 9;
            break;
        case M89RelevantContentLayoutEnum.ONE_COLUMN:
            size = isLong ? 8 : 3;
            break;
        case M89RelevantContentLayoutEnum.CAROUSEL:
            size = isLong ? 25 : 12;
            break;
        case M89RelevantContentLayoutEnum.ASYMMETRICAL_GIRD:
            size = isLong ? 12 : 4;
            break;
    }
    return size;
};
