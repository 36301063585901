import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledOneColumnList = styled.div({});

export const StyledListItem = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    padding: `50px 0`,
    borderTop: `1px solid ${theme.colors.green20}`,
    rowGap: 30,
    [breakpoints.sm]: {
        flexDirection: 'row',
        columnGap: 30,
    },
    '&:last-child': {
        borderBottom: `1px solid ${theme.colors.green20}`,
    },
}));

export const StyledImage = styled.div(() => ({
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 330,
    [breakpoints.sm]: {
        width: '50%',
    },
    [breakpoints.md]: {
        width: 570,
    },
}));

export const StyledContent = styled.div({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    flex: 1,
    hyphens: 'initial',
    [breakpoints.sm]: {
        maxWidth: 500,
    },
});

export const StyledMetaInfo = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spaces[4],
    flexWrap: 'wrap',
}));

export const StyledSpacer = styled.div({
    flex: 1,
    paddingTop: 20,
});

export const StyledButtonWrapper = styled.div({});
