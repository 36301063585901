import React, { memo } from 'react';
import { CallToAction, Link, Text, ImageLink } from '~/shared/components';
import {
    StyledOneColumnList,
    StyledListItem,
    StyledImage,
    StyledContent,
    StyledMetaInfo,
    StyledSpacer,
    StyledButtonWrapper,
} from './styled';
import { generateUuid } from '~/shared/utils/jsx';
import {
    RelewiseResponsePageTags,
    RelevantContentItem,
    RelevantContentResponse,
} from '~/lib/data-contract';
import { useTranslation } from '~/shared/utils/translation';
import { useComputed } from '~/shared/utils';
import { TagListLink } from '../TagListLink';
import NextLink from 'next/link';

interface OneColumnProps {
    items: RelevantContentResponse[];
}

const RenderMemoItem = memo(({ item }: { item: RelevantContentItem }) => {
    const { translate } = useTranslation();

    const ItemHeadline = () => <Text variant="display3">{item.headline}</Text>;

    return (
        <StyledListItem>
            <StyledContent>
                <StyledMetaInfo>
                    {(JSON.parse(item.tags) as RelewiseResponsePageTags[])
                        .map((item) => item.tagsList)
                        .map(
                            (tags) =>
                                tags &&
                                tags.map((tag, index) => (
                                    <TagListLink key={index}>{tag}</TagListLink>
                                )),
                        )}
                </StyledMetaInfo>
                {!item.callToAction && (
                    <span>
                        <ItemHeadline />
                        <StyledSpacer />
                    </span>
                )}

                {item.callToAction && (
                    <span>
                        <NextLink href={item.callToAction} passHref prefetch={false} legacyBehavior>
                            <Link style={{ textDecoration: 'auto' }}>
                                <Text variant="display3">{item.headline}</Text>
                            </Link>
                        </NextLink>
                        <StyledSpacer />
                        <StyledButtonWrapper>
                            <CallToAction
                                callToAction={{
                                    url: item.callToAction,
                                    title: translate(
                                        'Kompan.RelevantContent.ButtonLabelReadArticle',
                                    ),
                                    text: translate(
                                        'Kompan.RelevantContent.ButtonLabelReadArticle',
                                    ),
                                    iconName: 'arrow right',
                                    iconAlignment: 'start',
                                    variation: 'Plain',
                                }}
                            />
                        </StyledButtonWrapper>
                    </span>
                )}
            </StyledContent>
            {item.image && (
                <StyledImage>
                    <ImageLink
                        withBorderRadius
                        linkProps={{
                            href: `${item.callToAction}`,
                            passHref: true,
                            prefetch: false,
                        }}
                        imageProps={{
                            alt: '',
                            ...item.image,
                            height: item.image.height,
                            width: item.image.width,
                            objectFit: 'cover',
                            layout: 'fill',
                            draggable: false,
                        }}
                    />
                </StyledImage>
            )}
        </StyledListItem>
    );
});

export const OneColumn = ({ items }: OneColumnProps) => {
    const baseKey = useComputed(() => {
        return generateUuid();
    }, []);
    return (
        <StyledOneColumnList>
            {items.map((group, i) => (
                <React.Fragment key={i}>
                    {group.relevantContent.map((subItem, index) => (
                        <RenderMemoItem item={subItem} key={`${baseKey}_${index}`} />
                    ))}
                </React.Fragment>
            ))}
        </StyledOneColumnList>
    );
};
