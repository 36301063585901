import React from 'react';
import { Button, Sidepanel, DropdownItem } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';
import {
    AccordionFiltersBar,
    Facet,
    SelectedFacet,
} from '$templates/blocks/components/M140ProductsList';
import {
    StyledScrollArea,
    StyledDrawerFooter,
    ClearContainer,
    SubmitContainer,
    Title,
} from '$templates/blocks/components/M140ProductsList/components/filters/FiltersDrawer/styled';
import { useMarket } from '~/shared/utils';
type FiltersDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    onClearAll: () => void;
    onFacetChange: (
        event: React.ChangeEvent<HTMLInputElement> | undefined,
        facet: Facet,
        item?: DropdownItem
    ) => void;
    selectedFacets: SelectedFacet[];
    facets: Facet[];
    submitTitle: React.ReactChild | React.ReactChild[];
};

export const FiltersDrawer = ({
    isOpen,
    onClose,
    onClearAll,
    onFacetChange,
    selectedFacets,
    facets,
    submitTitle,
}: FiltersDrawerProps) => {
    const { translate } = useTranslation();
    const { culture } = useMarket();
    return (
        <Sidepanel
            culture={culture}
            maxWidth={675}
            open={isOpen}
            onClose={onClose}
            sidePadding={'large'}
            footerContent={
                <StyledDrawerFooter>
                    <ClearContainer>
                        <Button
                            onClick={onClearAll}
                            variant="Transparent"
                            disableHoverAnimation={true}
                        >
                            {translate('Kompan.Commerce.FiltersClearAll')}
                        </Button>
                    </ClearContainer>
                    <SubmitContainer>
                        <Button onClick={onClose} variant="Red" disableHoverAnimation={true}>
                            {submitTitle}
                        </Button>
                    </SubmitContainer>
                </StyledDrawerFooter>
            }
        >
            <StyledScrollArea>
                <Title variant="display3">{translate('Kompan.Commerce.Filters')}</Title>
                <AccordionFiltersBar
                    facets={facets}
                    selectedFacets={selectedFacets}
                    onFacetChange={onFacetChange}
                    isChild={true}
                    translationsGroup={'Kompan.RelevantContent'}
                />
            </StyledScrollArea>
        </Sidepanel>
    );
};
